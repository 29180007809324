import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { PageLoaderComponent } from './layout/page-loader/page-loader.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { RightSidebarComponent } from './layout/right-sidebar/right-sidebar.component';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';
import { WebLayoutComponent } from "./layout/app-layout/web-layout/web-layout.component";
import { LocationStrategy, HashLocationStrategy, DatePipe, UpperCasePipe } from '@angular/common';
import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
} from 'ngx-perfect-scrollbar';

import { ClickOutsideModule } from 'ng-click-outside';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Routes, RouterModule } from "@angular/router";
import { ExtendedModule } from "@angular/flex-layout";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { GetIconFileTypesPipe } from "./pipes/get-icon-file-types.pipe";
import { AppendBranchInterceptorService, HttpInterceptorService } from "./core/service/http-interceptor.service";
import { API_BASE_URL, ConfigFactory, ConfigService } from "./global/config";
import { LoaderInterceptor } from "./global/interceptors/loader-interceptor.service";
import { SharedComponentModule } from "./shared-component/shared-component.module";
import { MatChipsModule } from "@angular/material/chips";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from "@angular/material-moment-adapter";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS } from "ng-pick-datetime/date-time/adapter/moment-adapter/moment-date-time-adapter.class";


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false,
};

const routes: Routes = []; // sets up routes constant where you define your routes
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    PageLoaderComponent,
    SidebarComponent,
    RightSidebarComponent,
    AuthLayoutComponent,
    MainLayoutComponent,
    WebLayoutComponent

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    ClickOutsideModule,
    // core & shared
    CoreModule,
    SharedModule,
    ExtendedModule,
    MatSidenavModule,
    MatToolbarModule,
    MatSnackBarModule,
    SharedComponentModule,
    MatChipsModule,
    MatAutocompleteModule,

  ],
  providers: [
    // { provide: LocationStrategy, useClass: HashLocationStrategy },
    // {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } },
    { provide: OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS, useValue: { useUtc: false } },
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AppendBranchInterceptorService, multi: true },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    DatePipe,
    UpperCasePipe,
    ConfigService,
    { provide: 'CONFIGPATH', useValue: './assets/config.json' },
    { provide: 'APIURLVAR', useValue: 'API_URL' },
    {
      provide: API_BASE_URL, useFactory: ConfigFactory,
      deps: [ConfigService, 'CONFIGPATH', 'APIURLVAR']
    }
  ],
  entryComponents: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
