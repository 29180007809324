<div class="row p-15">
  <h4 class="text-black" *ngIf="headerHide != 1">Professional Development Appraisal</h4>
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 f-13">
    <div class="m-t-15" [innerHTML]="htmlBody"></div>
  </div>

  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 f-13 parent-area"
       *ngIf="signatureLength < 10">
    <form class="register-form filter-form" [formGroup]="sectionForm">
      <h5 class="form-group-heading">Your Signature</h5>
      <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 mb-2 p-0">
        <label class="f-12 m-t-15">Signature</label>
        <div *ngIf="!isNewSignature">
                        <span *ngIf="isEdit" (click)="removeSignature()"
                              class="badge badge-danger remove-signature pointer b-r-15">x</span>
          <img src="{{ signatureImg }}">
        </div>
        <ng-container *ngIf="isNewSignature">
          <div [ngClass]="{'signature-invalid': sigError}">
            <app-signature-pads (signaturePadEmit)="signatureImgOutput($event)"></app-signature-pads>
            \
          </div>
        </ng-container>
        <br/>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Signature Date</mat-label>
          <input matInput formControlName="date" [matDatepicker]="picker" (click)="picker.open()" required>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <br/>
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 p-r-0" *ngIf="data['feedback'] == null">
        <button mat-flat-button color="primary" (click)="formSubmit(sectionForm)" class="pull-right  text-white"
                type="submit">
          Submit
        </button>
        <button class="pull-right cancel-btn text-white mr-3  bg-light-red" type="button" (click)="modalClose()"
                mat-raised-button data-dismiss="modal">Close
        </button>
      </div>
    </form>
  </div>
</div>
